<template>
  <div class="app-container">
    <div class="filter-container">
      <el-button
        class="filter-item"
        type="primary"
        @click="checkDialogFormVisible(true, {})"
        >新增</el-button
      >
    </div>

    <div style="margin-bottom: 5px">VIP数量:{{ total }}</div>

    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      size="small"
      border
      fit
      highlight-current-row
    >
      <el-table-column
        align="center"
        prop="id"
        label="ID"
        width="95"
      ></el-table-column>
      <el-table-column
        label="昵称"
        min-width="80"
        align="center"
        prop="name"
      ></el-table-column>
      <el-table-column
        label="权益"
        min-width="200"
        align="center"
        prop="equity"
      >
        <template slot-scope="scope">
          <div>
            <div>是否分销：{{ scope.row.isDistribution ? "是" : "否" }}</div>
            <div>消费折扣：{{ scope.row.castDiscount }}</div>
            <div>赠送积分：{{ scope.row.giveIntegral }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="购买金额（元）"
        min-width="80"
        align="center"
        prop="amount"
      ></el-table-column>
      <el-table-column label="状态" min-width="80" align="center" prop="status">
        <template slot-scope="scope">
          <span>{{ getValue(VIP_GRADE_STATUS, scope.row.status) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="等级"
        min-width="80"
        align="center"
        prop="grade"
      ></el-table-column>
      <el-table-column
        label="介绍说明"
        align="center"
        prop="introduction"
        min-width="200"
      >
        <template slot-scope="scope">
          <div v-html="scope.row.introduction"></div>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="created_at"
        label="创建时间"
        min-width="100"
      >
        <template slot-scope="scope">
          <i class="el-icon-time" />
          <span>{{ formatApiDate(scope.row.created_at) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="created_at"
        label="修改时间"
        min-width="100"
      >
        <template slot-scope="scope">
          <i class="el-icon-time" />
          <span>{{ formatApiDate(scope.row.updated_at) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        width="230"
        class-name="small-padding fixed-width"
      >
        <template slot-scope="scope">
          <el-button
            type="warning"
            size="mini"
            @click="checkDialogFormVisible(true, scope.row)"
            >编辑</el-button
          >
          <el-popconfirm
            confirm-button-text="确定"
            cancel-button-text="不用了"
            icon="el-icon-info"
            icon-color="red"
            title="确定要修改状态吗？"
            @confirm="statusChange(scope.row)"
          >
            <el-switch
              :value="scope.row.status === 1"
              slot="reference"
              style="margin-left: 8px"
            ></el-switch>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50, 100]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

    <!--    编辑和新增-->
    <el-dialog
      :title="DIALOG_TITLE_TYPE[dialogStatus]"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
    >
      <el-form
        ref="form"
        :rules="rules"
        :model="form"
        label-position="left"
        label-width="150px"
        style="width: 80%; margin-left:50px;"
      >
        <el-form-item label="名称" prop="name">
          <el-input v-model="form.name" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="购买价格" prop="amount">
          <el-input v-model="form.amount" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="是否可以分销" prop="isDistribution">
          <el-select
            v-model="form.isDistribution"
            placeholder="是否可以分销"
            style="width: 100%"
            class="filter-item"
            clearable
          >
            <el-option label="是" :value="true" />
            <el-option label="否" :value="false" />
          </el-select>
        </el-form-item>
        <el-form-item label="消费折扣" prop="castDiscount">
          <el-input v-model="form.castDiscount" placeholder="请输入">
            <template slot="append">折</template>
          </el-input>
        </el-form-item>
        <el-form-item label="赠送积分" prop="giveIntegral">
          <el-input v-model="form.giveIntegral" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="等级" prop="grade">
          <el-input v-model="form.grade" placeholder="值越大等级越高" />
        </el-form-item>
        <el-form-item label="介绍说明" prop="introduction">
          <quill-editor
            v-model="form.introduction"
            class="content_warp"
          ></quill-editor>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="checkDialogFormVisible(false, {})">取消</el-button>
        <el-button type="primary" @click="saveData" :loading="btnLoading"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getUserVipList, createVipCard, switchUserVipStatus } from "@/api/user";
import {
  DIALOG_TITLE_TYPE,
  VIP_GRADE_STATUS,
  getValue
} from "@/utils/enumUtile";
import { formatApiDate } from "@/utils/utils";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";

export default {
  computed: {
    DIALOG_TITLE_TYPE() {
      return DIALOG_TITLE_TYPE;
    },
    VIP_GRADE_STATUS() {
      return VIP_GRADE_STATUS;
    },
    formatApiDate() {
      return formatApiDate;
    }
  },
  components: { quillEditor },
  filters: {},
  data() {
    return {
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10
      },
      dialogStatus: "",
      rules: {
        amount: [
          { required: true, message: "购买价格不能为空", trigger: "change" }
        ],
        name: [{ required: true, message: "名称不能为空", trigger: "change" }],
        equity: [
          { required: true, message: "权益不能为空", trigger: "change" }
        ],
        introduction: [
          { required: true, message: "介绍说明不能为空", trigger: "change" }
        ],
        grade: [{ required: true, message: "等级不能为空", trigger: "change" }],
        isDistribution: [
          { required: true, message: "是否可以分销不能为空", trigger: "change" }
        ],
        castDiscount: [
          { required: true, message: "消费折扣不能为空", trigger: "change" }
        ],
        giveIntegral: [
          { required: true, message: "赠送积分不能为空", trigger: "change" }
        ]
      },
      form: {
        id: 0,
        name: "",
        amount: "", // 购买价格
        equity: {}, // 权益
        introduction: "", // 介绍说明
        grade: "", // 等级
        isDistribution: "", // 是否可以分销
        castDiscount: "", // 消费折扣
        giveIntegral: "" // 赠送积分
      },
      btnLoading: false,
      dialogFormVisible: false,
      editObj: {} // 修改的对象
    };
  },
  created() {
    this.getList();
  },
  watch: {},
  methods: {
    getValue,
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    /**
     * 查询用户列表
     */
    getList() {
      this.listLoading = true;
      const form = this.listQuery;
      const params = {
        ...form
      };
      getUserVipList(params)
        .then(response => {
          const data = response.data.data;
          data.map(item => {
            const equity = JSON.parse(item.equity);
            item.isDistribution = equity.isDistribution;
            item.castDiscount = equity.castDiscount;
            item.giveIntegral = equity.giveIntegral;
          });
          this.list = response.data.data;
          this.total = response.data.total;
        })
        .finally(() => {
          this.listLoading = false;
        });
    },

    /**
     * 确认添加还是编辑
     */
    saveData() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          const form = this.form;
          const params = {
            ...form
          };
          const equity = {
            isDistribution: form.isDistribution, // 是否可以分销
            castDiscount: form.castDiscount, // 消费折扣
            giveIntegral: form.giveIntegral // 赠送积分
          };
          params.equity = JSON.stringify(equity);

          delete params.isDistribution;
          delete params.castDiscount;
          delete params.giveIntegral;

          this.btnLoading = true;
          createVipCard(params)
            .then(() => {
              this.getList();
              this.checkDialogFormVisible(false, {});
              this.$notify({
                title: "成功",
                message: "保存成功",
                type: "success",
                duration: 2000
              });
            })
            .finally(() => {
              this.btnLoading = false;
            });
        }
      });
    },

    /**
     * 切换状态
     * @param status
     * @param editObj
     */
    checkDialogFormVisible(status, editObj = {}) {
      this.dialogFormVisible = status;
      this.editObj = editObj;
      if (status) {
        this.dialogStatus =
          editObj && Object.keys(editObj).length !== 0 ? "UPDATE" : "CREATE";
      } else {
        this.dialogStatus = "";
      }
      if (editObj && Object.keys(editObj).length !== 0) {
        this.form.id = editObj.id;
        this.form.amount = editObj.amount;
        this.form.name = editObj.name;
        this.form.introduction = editObj.introduction;
        this.form.grade = editObj.grade;
        const equity = JSON.parse(editObj.equity);
        this.form.isDistribution = equity.isDistribution;
        this.form.castDiscount = equity.castDiscount;
        this.form.giveIntegral = equity.giveIntegral;
        this.form.equity = editObj.equity;
      } else {
        this.form.id = 0;
        this.form.name = "";
        this.form.amount = "";
        this.form.equity = {};
        this.form.introduction = "";
        this.form.grade = "";
        this.form.isDistribution = "";
        this.form.castDiscount = "";
        this.form.giveIntegral = "";
        this.$refs.form.resetFields();
      }
    },
    /**
     * 修改vip等级状态
     * @param scope
     */
    statusChange(scope) {
      const params = {
        id: scope.id,
        status: scope.status === 1 ? 2 : 1
      };
      switchUserVipStatus(params).then(() => {
        this.getList();
        this.$notify({
          title: "成功",
          message: "状态编辑成功",
          type: "success",
          duration: 2000
        });
      });
    }
  }
};
</script>
<style scoped lang="scss">
.content_warp {
  /deep/ {
    .ql-container {
      min-height: 400px;
    }
    .ql-editor {
      min-height: 400px;
    }
  }
}
</style>
